<template>
  <div class="h-full w-800">
    <v-container fluid class="base-table-padding-left-none">
      <v-data-table
        id="sharedTripsTable"
        :loading="loading"
        :headers="headers"
        :items="sharedTrips"
        item-key="quoteId"
        :pagination.sync="pagination"
      >
        <template #items="props">
          <td
            class="text-xs text-primary cursor-pointer"
            @click="navigateToQuote(props.item)"
          >
            {{ props.item.quoteId }}
          </td>
          <td
            class="text-xs text-primary cursor-pointer"
            @click="navigateToReservation(props.item)"
          >
            {{ props.item.reservationManagedId }}
          </td>
          <td class="text-xs">{{ props.item.invitedByName }}</td>
          <td class="text-xs">{{ props.item.routeName }}</td>
          <td class="text-xs">{{ formatDate(props.item.pickupDateTime) }}</td>
          <td class="text-xs">{{ formatTime(props.item.pickupDateTime) }}</td>
          <td class="d-flex align-center">
            <v-tooltip top>
              <template #activator="{ on }">
                <div v-on="on">
                  <v-icon
                    small
                    class="mx-1"
                    color="primary"
                    :height="24"
                    :width="24"
                    @click="shareTrip(props.item)"
                  >
                    share
                  </v-icon>
                </div>
              </template>
              <span>Share</span>
            </v-tooltip>
            <v-tooltip top>
              <template #activator="{ on }">
                <div v-on="on">
                  <v-icon
                    small
                    class="mx-1"
                    color="primary"
                    :height="24"
                    :width="24"
                    @click="unshareTrip(props.item)"
                  >
                    link_off
                  </v-icon>
                </div>
              </template>
              <span>Unshare</span>
            </v-tooltip>
          </td>
        </template>
      </v-data-table>
    </v-container>
  </div>
</template>

<script>
import customerAccounts from '@/services/customerAccounts'
import { DateTime } from 'luxon'
import { EventBus } from '@/utils/event-bus'
import CRIcon from '@/cr/components/CRIcon.vue'
import { shareQuote, unshareQuote } from '@/services/quotes'
import { shareReservation, unshareReservation } from '@/services/reservations'

export default {
  components: { CRIcon },
  data() {
    return {
      loading: false,
      pagination: {
        page: 1,
        rowsPerPage: 10,
        totalItems: 0,
        sortBy: 'pickupDateTime',
        descending: true,
      },
      sharedTrips: [],
      headers: [
        {
          align: 'left',
          text: 'Quote ID',
          value: 'quoteId',
        },
        {
          align: 'left',
          text: 'Reservation ID',
          value: 'reservationManagedId',
        },

        {
          align: 'left',
          text: 'Invited By',
          value: 'invitedByName',
        },
        {
          align: 'left',
          text: 'Route',
          value: 'routeName',
        },
        {
          align: 'left',
          text: 'Pickup Date',
          value: 'pickupDateTime',
        },
        {
          align: 'left',
          text: 'Pickup Time',
          value: 'pickupDateTime',
          sortable: false,
        },
        {
          align: 'left',
          text: 'Actions',
          value: 'userId',
          sortable: false,
        },
      ],
    }
  },
  props: {
    row: {
      type: Object,
      required: true,
    },
  },
  async mounted() {
    this.getTable()
    EventBus.$on('global-table-view-refresh', this.getTable)
  },
  methods: {
    async getTable() {
      this.loading = true

      let sharedTrips = []
      try {
        const userData = await customerAccounts.getUserSharedTrips(
          this.row.userId
        )
        sharedTrips = userData.data
      } catch (error) {
        console.error(error)
        this.$store.dispatch('app/showAlert', {
          type: 'error',
          message: 'Error fetching shared trips',
        })
        this.loading = false
      }

      this.sharedTrips = sharedTrips
      this.loading = false
    },
    formatDate(date) {
      return !!date ? DateTime.fromISO(date).toFormat('d MMM yy') : ''
    },
    formatTime(date) {
      return !!date ? DateTime.fromISO(date).toFormat('h:mm a') : ''
    },
    navigateToQuote(item) {
      const route = this.$router.resolve({
        name: 'quotes.view',
        params: { id: item.quoteId },
      })
      window.open(route.href, '_blank')
    },
    navigateToReservation(item) {
      const route = this.$router.resolve({
        name: 'reservation-detail',
        params: { id: item.reservationId },
      })
      window.open(route.href, '_blank')
    },
    async shareTrip(item) {
      this.loading = true
      try {
        if (item.reservationId) {
          await shareReservation(item.reservationId, this.row.userId)
          this.$store.dispatch('app/showAlert', {
            type: 'success',
            message: 'Reservation shared successfully',
          })
        } else {
          await shareQuote(item.quoteId, this.row.userId)
          this.$store.dispatch('app/showAlert', {
            type: 'success',
            message: 'Quote shared successfully',
          })
        }
        this.loading = false
      } catch (error) {
        this.loading = false
        console.error(error)
        this.$store.dispatch('app/showAlert', {
          type: 'error',
          message: 'Error unsharing trip',
        })
      }
    },
    async unshareTrip(item) {
      this.loading = true
      try {
        if (item.reservationId) {
          await unshareReservation(item.reservationId, this.row.userId)
          this.$store.dispatch('app/showAlert', {
            type: 'success',
            message: 'Reservation unshared successfully',
          })
        } else {
          await unshareQuote(item.quoteId, this.row.userId)
          this.$store.dispatch('app/showAlert', {
            type: 'success',
            message: 'Quote unshared successfully',
          })
        }
        EventBus.$emit('global-table-view-refresh')
        await this.getTable()
        this.loading = false
      } catch (error) {
        this.loading = false
        console.error(error)
        this.$store.dispatch('app/showAlert', {
          type: 'error',
          message: 'Error unsharing trip',
        })
      }
    },
  },
}
</script>
